import { createRouter, createWebHashHistory } from 'vue-router';
import App from './App.vue';

const routes = [
    {
        path: '/dashboard',
        name: 'app',
        component: App,
        children: [
            {
                path: '',
                name: 'dashboard',
                component: () => import('./components/Dashboard.vue')
            },
            {
                path: '/usuarios',
                name: 'Usuários',
                component: () => import('./paginas/usuarios/index.vue')
            },
            {
                path: '/empresas',
                name: 'Empresas',
                component: () => import('./paginas/empresas/index.vue')
            },
            {
                path: '/unidades',
                name: 'Unidades',
                component: () => import('./paginas/unidades/index.vue')
            },
            {
                path: '/usinas',
                name: 'Usinas',
                component: () => import('./paginas/usinas/index.vue')
            },
            {
                path: '/energia-injetada',
                name: 'Editar Energia Injetada',
                component: () => import('./paginas/energia/editar.vue')
            },
            
            {
                path: '/areas',
                name: 'Áreas',
                component: () => import('./paginas/areas/index.vue')
            },
            {
                path: '/tipos',
                name: 'Tipos',
                component: () => import('./paginas/tipos/index.vue')
            },
            {
                path: '/faturas/tipos',
                name: 'Tipos Faturas',
                component: () => import('./paginas/tipos_faturas/index.vue')
            },
            {
                path: '/regional',
                name: 'Regional',
                component: () => import('./paginas/regional/index.vue')
            },
            {
                path: '/faturas',
                name: 'Faturas',
                component: () => import('./paginas/faturas/index.vue')
            },
            {
                path: '/economia',
                name: 'Relatório de Economia',
                component: () => import('./paginas/relatorios/economia.vue')
            },
            {
                path: '/consolidado',
                name: 'Relatório Consolidado',
                component: () => import('./paginas/relatorios/consolidado.vue')
            },
            {
                path: '/divida-ativa',
                name: 'Divida',
                component: () => import('./paginas/divida/index.vue')
            },

            {
                path: '/relatorios/faturas',
                name: 'Relatório de Faturas',
                component: () => import('./paginas/relatorios/faturas.vue')
            },
            {
                path: '/relatorios/base',
                name: 'Relatório de Base Cadastral',
                component: () => import('./paginas/relatorios/base.vue')
            },
            {
                path: '/energia',
                name: 'Relatório de Energia Injetada',
                component: () => import('./paginas/energia/energia.vue')
            },
            {
                path: '/importar',
                name: 'Importar de Energia Injetada',
                component: () => import('./paginas/importar/index.vue')
            },
            
            {
                path: '/atualizar-rateio',
                name: 'Atualizar Rateio',
                component: () => import('./paginas/importar/rateio.vue')
            },
            
        ]
    },
    {
        path: '/',
        name: 'login',
        component: () => import('./pages/Login.vue')
    },
    {
        path: '/landing',
        name: 'landing',
        component: () => import('./pages/LandingDemo.vue')
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue')
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue')
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
